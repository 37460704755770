import request from '@/utils/request'
// 获取上传记录
export function getThirdPartyrecord (data) {
  return request({
    url: '/report/record',
    method: 'post',
    data
  })
}
// 获取下拉框数据
export function platList (data) {
  return request({
    url: '/plat/platList',
    method: 'get',
    data
  })
}
// 下载模板
export function downloadTemplate (data) {
  return request({
    url: '/report/downloadTemplate',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
// 提交审核
export function upload (data) {
  return request({
    url: '/report/upload',
    method: 'post',
    data
  })
}
